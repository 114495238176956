import React                from 'react'
import cx                   from 'utils/classnames'
import styles               from './styles.module.scss'

const NavBarNav = (props) => {
    const {
        children,
        className,
        ...restProps
    } = props

    return (
        <ul
            role="menubar"
            className={cx(
                styles.root,
                className
            )}
            {...restProps}
        >
            {children}
        </ul>
    )
}

export default NavBarNav
