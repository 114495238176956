import React                  from 'react'
import Icon                   from 'entrada-ui/Icon'
import Link                   from 'components/Link'
import { FaShoppingCart }     from 'react-icons/fa'
import styles                 from './styles.module.scss'

const CartIcon = ({ badge }) => {
    return (
        <Link to="/cart" className={styles.cartIcon}>
            <Icon>
                {badge &&
                <span className={styles.badge}>{badge}</span>
                }
                <FaShoppingCart />
            </Icon>
        </Link>
    )
}

export default CartIcon
