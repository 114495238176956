import React              from 'react'
import {
  graphql,
  useStaticQuery
}                         from 'gatsby'
import ApolloClient       from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'
import fetch              from 'isomorphic-fetch'
import App                from 'components/App'

const client = new ApolloClient({
    uri: process.env.GATSBY_PIH_URL,
    // uri: 'http://localhost:8000/_graphql',
    // ssrMode: true,
    fetch,
    request: typeof window !== 'undefined' ? (operation) => {
      const token = localStorage.getItem('apiToken')
      if(!token) {
        return
      }

      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : ''
        }
      })
    } : undefined
});

export const wrapPageElement = ({ element, props }) => (
  <App {...props}>
    {element}
  </App>
)

//! NOTE It's not possible to include here components that use StaticQuery:
// https://github.com/gatsbyjs/gatsby/issues/14799#issuecomment-510217462
export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    {element}
  </ApolloProvider>
);
