import React                from 'react'
import {
    graphql,
    useStaticQuery
}                           from 'gatsby'
import { map }              from 'lodash-es'
import {
    FaTwitter,
    FaFacebook,
    FaInstagram,
}                           from 'react-icons/fa'
import {
    Col,
    Container,
    Row
}                           from 'react-bootstrap'
import Icon                 from 'entrada-ui/Icon'
import Link                 from 'components/Link'
import Logo                 from 'components/Logo'
import FooterNav            from './FooterNav'
import styles               from './styles.module.scss'

const FOOTER_MENU_QUERY = graphql`
{
    wordpressMenusMenusItems(name: {eq: "Footer menu"}) {
        items {
            title
            slug
            url
            child_items {
              title
              slug
              url
            }
          }
    }
}
`

const AppFooter = (props) => {
    const {
        wordpressMenusMenusItems: {
            items
        }
    } = useStaticQuery(FOOTER_MENU_QUERY)

    return (
        <div className={styles.appFooter}>
            <Container>
                <Row>
                    <Col lg={2}>
                        <div className="text-center">
                            <h4>Follow us</h4>
                            <div className={styles.socialIcons}>
                                <a href="https://www.facebook.com/GreatSightsNZ/" target="_blank">
                                    <Icon className={styles.socialIcon}>
                                        <FaFacebook />
                                    </Icon>
                                </a>

                                <a href="https://www.instagram.com/greatsightsnz/" target="_blank">
                                    <Icon className={styles.socialIcon}>
                                        <FaInstagram />
                                    </Icon>
                                </a>
                            </div>
                        </div>
                    </Col>
                    <Col lg={10}>
                        <FooterNav menu={items}>
                            {map(items, (item, idx) => (
                            <FooterNav.Item
                                key={idx}
                                title={item.title}
                                parentSlug={item.slug}
                                items={item.child_items}
                            />
                            ))}
                        </FooterNav>

                        <hr />

                        <div className={styles.subFooter}>
                            <Logo className={styles.logo} dark={true} />
                            <div className={styles.termsWrap}>
                                <div>
                                    <a href="https://entradatravelgroup.com/privacy-policy/">Privacy Policy</a> | <Link to="/travel-info/terms-and-conditions"> Terms and Conditions </Link>
                                </div>
                                <div>
                                    &copy; 2016 - {new Date().getFullYear()} Entrada Travel Group
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AppFooter
