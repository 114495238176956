import React                from 'react'
import cx                   from 'utils/classnames'
import { NavBarContext }    from 'entrada-ui/NavBar'
import {
    useContext,
    getContextState
}                           from 'entrada-ui/utils'
import styles               from './styles.module.scss'

const NavBarToggler = (props) => {
    const {
        children,
        className,
    } = props

    const context = useContext(NavBarContext)
    const { open } = getContextState({ props, states: ['open'], context })

    const onClick = React.useCallback(() => context.setOpen(!open), [open, context])

    return (
        <button
            type="button"
            className={cx(
                styles.root,
                {
                    [styles.open]: open,
                },
                className
            )}
            onClick={onClick}
        >
            {children}
        </button>
    )
}

NavBarToggler.etgName = 'NavBarToggler'

export default NavBarToggler
