import React                  from 'react'
import cx                     from 'utils/classnames'
import {
    Button
}                             from 'react-bootstrap'
import CartHandler            from 'components/CartHandler'
import Modal                  from 'components/Modal'
import {
    useContext
}                             from 'entrada-ui/utils'
import styles                 from './styles.module.scss'


const refresh = () => {
    window.location.reload()
}

const TokenExpiredModal = () => {
    const context = useContext(CartHandler.Context)

    const { sessionTokenExpired } = context

    React.useEffect(() => {
        if(sessionTokenExpired) {
            window.localStorage.clear()
        }
    }, [sessionTokenExpired])

    return (
        <Modal
            show={sessionTokenExpired}
            onHide={() => {/* Disable hidding modal */}}
        >
            <Modal.Header>
                <Modal.Title>
                    Cart session expired
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="pt-4 pb-4">
                <p className="pt-2 pb-2">
                    Sorry, your cart session expired. Please refresh your browser.
                </p>

                <div className="text-right">
                    <Button className="text-uppercase" variant="primary" size="lg" href="#" onClick={refresh}>
                        Refresh
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default TokenExpiredModal
