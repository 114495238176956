import React                from 'react'
import cx                   from 'utils/classnames'
import { NavBarContext }    from 'entrada-ui/NavBar'
import {
    useContext,
    isEtgElement
}                           from 'entrada-ui/utils'
import styles               from './styles.module.scss'

const NavBarNavItem = (props) => {
    const {
        children,
        className,
    } = props

    const context = useContext(NavBarContext)

    const handleLinkClick = () => context.setOpen(false)

    return (
        <li
            className={cx(
                styles.root,
                className,
            )}
            role="none"
        >
            {React.Children.map(children, (child, index) => (
                React.cloneElement(child, {
                    role: isEtgElement(child, ['input', 'Link']) ? 'menuitem' : undefined,
                    onClick: handleLinkClick
                })
            ))}
        </li>
    )
}

export default NavBarNavItem
