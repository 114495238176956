import React     from 'react'
import cx        from 'utils/classnames'
import PropTypes from 'utils/prop-types'
import styles    from './styles.module.scss'

const fontSizeClass = {
    default: styles['font-size-default'],
    small: styles['font-size-small'],
    large: styles['font-size-large'],
}

const Icon = React.forwardRef(function Icon(props, ref) {
    const {
        className,
        component: Component,
        fontSize,
        children,
        ...rest
    } = props
    return (
        <Component
            ref={ref}
            {...rest}
            className={cx(
                styles.icon,
                fontSizeClass[fontSize],
                className,
            )}
        >
            {children}
        </Component>
    )
})

Icon.propTypes = {
    className: PropTypes.string,
    component: PropTypes.string,
    fontSize: PropTypes.oneOf(['default', 'small', 'large']),
}

Icon.defaultProps = {
    fontSize: 'default',
    component: 'span'
}

export default Icon
