import React                from 'react'
import {
  map,
  max,
  chunk
}                           from 'lodash-es'
import Accordion            from 'entrada-ui/Accordion'
import Link                 from 'components/Link'
import styles               from './styles.module.scss'

const FooterNavItem = (props) => {
  const {
    title,
    // maxItemsPerCol,
    parentSlug,
    items = []
  } = props

  // const columns = chunk(items, maxItemsPerCol)
  const columns = chunk(items, Math.ceil(items.length/2))

  return (
    <Accordion.Item className={styles.accordionItem}>
        <Accordion.Toggler className={styles.accordionToggler}>
            <Accordion.Title className={styles.accordionTitle}>
              {title}
            </Accordion.Title>
        </Accordion.Toggler>

        <Accordion.Content
            className={styles.accordionContent}
            classes={{
                body: styles.accordionContentBody
            }}
        >
            {map(columns, (col, colIdx) => (
            <ul key={colIdx}>
                {map(col, (item, itemIdx) => (
                  <li key={itemIdx}>
                    <Link to={
                      item.slug ?
                      (parentSlug ? `/${parentSlug}/${item.slug}` : `/${item.slug}`)
                      :
                      item.url
                    }>
                      {item.title.replace('&#038;', '&').replace('&#39', "'")}
                    </Link>
                  </li>
                ))}
            </ul>
            ))}
        </Accordion.Content>

    </Accordion.Item>
  )
}

const FooterNav = ({ children, menu }) => {
  const maxItemsPerCol = Math.ceil( max(map(menu, (item) => item.child_items.length)) / 2)

  return (
    <Accordion className={styles.accordion}>
      {React.Children.map(children, (child, index) => (
        React.cloneElement(child, {
          maxItemsPerCol
        })
      ))}
    </Accordion>
  )
}

FooterNav.Item = FooterNavItem

export default FooterNav
