import React                from 'react'
import cx                   from 'utils/classnames'
import { NavBarContext }    from 'entrada-ui/NavBar'
import {
    disableBodyScroll,
    enableBodyScroll,
}                           from 'body-scroll-lock'
import {
    useContext,
    getContextState
}                           from 'entrada-ui/utils'
import styles               from './styles.module.scss'

let scrollTarget = null

const NavBarCollapse = (props) => {
    const {
        children,
        className,
        Logo,
        // MenuProps = {},
        BackdropProps = {},
    } = props

    const context = useContext(NavBarContext)
    const { open } = getContextState({ props, states: ['open'], context })

    React.useEffect(() => {
        scrollTarget = document.querySelector(styles.root)
    }, []);

    React.useEffect(() => {
        open ?
        disableBodyScroll(scrollTarget) :
        enableBodyScroll(scrollTarget)
    }, [open])

    const handleClose = React.useCallback(() => context.setOpen(false), [context])

    return (
        <>
            <div
                className={cx(
                    styles.backdrop,
                    {
                        [styles.open]: open,
                    },
                    BackdropProps.className,
                )}
                onClick={handleClose}
            />

            <div
                className={cx(
                    styles.navBarCollapse,
                    {
                        [styles.open]: open,
                    },
                    className
                )}
            >
                <div className={styles.navBarHeader}>
                    <div className={styles.closeMenu} onClick={handleClose}>
                        <div></div>
                        <div></div>
                    </div>
                    {Logo}
                </div>
                {children}
            </div>
        </>
    )
}

NavBarCollapse.etgName = 'NavBarCollapse'

export default NavBarCollapse
