import React                  from 'react'
import cx                     from 'utils/classnames'
import {
    useStaticQuery,
    graphql
}                             from 'gatsby'
import { map }                from 'lodash-es'
import {
    useContext,
    getContextState
}                             from 'entrada-ui/utils'
import NavBar                 from 'entrada-ui/NavBar'
import NavBarToggler          from 'entrada-ui/NavBarToggler'
import NavBarCollapse         from 'entrada-ui/NavBarCollapse'
import Nav                    from 'entrada-ui/NavBarNav'
import NavItem                from 'entrada-ui/NavBarNavItem'
import Spacer                 from 'entrada-ui/Spacer'
import CartHandler            from 'components/CartHandler'
import Link                   from 'components/Link'
import Logo                   from 'components/Logo'
import MenuIcon               from './MenuIcon'
import CartIcon               from './CartIcon'
import chevron                from 'images/navbar-chevron.svg'
import styles                 from './styles.module.scss'

const HEADER_QUERY = graphql`
{
    wordpressMenusMenusItems(name: {eq: "Main menu"}) {
        items {
            slug
            title
            url
            type
        }
    }
}
`

let prevScroll = 0

const AppHeader = (props) => {
    const wrapperRef = React.useRef()
    const navBarRef = React.useRef()
    const [navHeight, setNavHeight] = React.useState(0)
    const [scrollingDown, setScrollingDown] = React.useState(false)
    // This is true when we scrolled more than the height of the nav bar.
    // At this point we potentialy are going to hide the nav bar if we are scrolling down.
    const [navBarOffsetted, setNavBarOffsetted] = React.useState(false)

    React.useEffect(() => {
        const handleScrollWindow = (ev) => {
            const newScroll = window.pageYOffset
            setScrollingDown(prevScroll < newScroll)
            setNavBarOffsetted(newScroll >= navBarRef.current.clientHeight)
            setNavHeight(navBarRef.current.clientHeight)

            prevScroll = newScroll
        }

        window.addEventListener('scroll', handleScrollWindow)


        return () => window.removeEventListener('scroll', handleScrollWindow)
    }, [])

    const {
        wordpressMenusMenusItems: {
            items
        }
    } = useStaticQuery(HEADER_QUERY)

    const context = useContext(CartHandler.Context)
    const {
        totalCartItems,
    } = getContextState({
        props,
        states: [
            'totalCartItems',
        ],
        context
    })

    return (
        <div ref={wrapperRef} className={styles.navBarWrapper} style={{ height: navHeight ? `${navHeight}px` : undefined }}>
            <NavBar
                ref={navBarRef}
                className={cx(
                    styles.navBar,
                    navHeight && styles.navBarFixed,
                    scrollingDown && navBarOffsetted && styles.hideNavBar
                )}
                aria-label="Main"
            >
                <NavBarToggler>
                    <MenuIcon />
                </NavBarToggler>
                <Logo className={styles.logo} dark={true} />
                <img className={styles.chevron} src={chevron} alt="Menu Chevron"/>
                <Spacer />
                <NavBarCollapse
                    Logo={<Logo className={styles.menuLogo} />}
                >
                    <Nav aria-label="Main">
                        {map(items, (item, idx) => (
                        <NavItem key={idx}>
                            {item.type === 'post_type' ?
                            <Link className={styles.link} to={`/${item.slug}`}>{item.title}</Link>
                            :
                            <Link className={styles.link} to={`${item.url}`}>{item.title}</Link>
                            }
                        </NavItem>
                        ))}
                    </Nav>
                </NavBarCollapse>
                {!!totalCartItems &&
                <CartIcon badge={totalCartItems} />
                }
            </NavBar>
        </div>
    )
}

export default AppHeader



