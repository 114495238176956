import React                  from 'react'
import { navigate }           from 'gatsby'
import {
    filter,
    find,
    map,
    reduce
}                             from 'lodash-es'
import gql                    from 'graphql-tag'
import {
    useApolloClient,
}                             from '@apollo/react-hooks'
import moment from 'moment-timezone'
import { Logtail }          from "@logtail/browser"

const logtail = new Logtail("GWjSo82ZNS3JWUMZo2sqMGeh")

const INIT_CART = gql`
mutation initialiseCart {
    initialiseCart(agentID: 76) {
        token
        cart {
            bookingReference
        }
    }
}
`

const ADD_TOUR = gql`
mutation addTour(
    $bookingReference: ID!,
    $tourCode: ID!,
    $departureDate: String!,
    $numAdults: Int!,
    $numChildren: Int!,
    $numInfants: Int!,
    $options: [CartOption]!,
    $pickupID: ID,
    $dropoffID: ID
) {
    addTour(
        bookingReference: $bookingReference,
        tourCode: $tourCode,
        departureDate: $departureDate,
        numAdults: $numAdults,
        numChildren: $numChildren,
        numInfants: $numInfants,
        options: $options,
        pickupID: $pickupID,
        dropoffID: $dropoffID,
    ) {
        id
        bookingReference
        cost
    }
}
`

const CART_FIELDS = `
bookingReference
expiresAt
cost
cartStatus
cartItems {
    id
    cost
    itemParentID
}
`

const GET_CART = gql`
query getCart($bookingReference: ID!) {
    getCart(bookingReference: $bookingReference) {
        ${CART_FIELDS}
    }
}
`

const REMOVE_TOUR = gql`
mutation removeTour(
    $bookingReference: ID!,
    $cartTourID: ID!
) {
    removeTour(
        bookingReference: $bookingReference,
        cartTourID: $cartTourID
    ) {
        ${CART_FIELDS}
    }
}
`

const ADD_PASSENGER_DETAILS = gql`
mutation addPassengerDetails(
    $bookingReference: ID!,
    $passengers: [CartPassenger!]!
) {
    addPassengerDetails(
        bookingReference: $bookingReference,
        passengers: $passengers
    ) {
        id
        index
        firstName
        lastName
        email
        phoneNumber
        isLeadTraveller
        category
    }
}
`

const GET_PAYMENT_SESSION_ID = gql`
query getPaymentSessionID(
    $bookingReference: ID!,
    $cost: Float!,
    $returnURL: String!
) {
    getPaymentSessionID(
        bookingReference: $bookingReference,
        cost: $cost,
        returnURL: $returnURL
    )
}
`

const GET_PAYMENT_CONFIRMATION = gql`
query getPaymentConfirmation(
    $bookingReference: ID!
) {
    getPaymentConfirmation(
        bookingReference: $bookingReference
    ) {
        status
    }
}
`

const CartHandlerContext = React.createContext()

const nestCartItems = (items, id = null) => (
    items
      .filter(item => item.itemParentID === id)
      .map(item => {
            const options = nestCartItems(items, item.id)
            const total = item.cost + reduce(options, (acc, opt) => acc + opt.cost, 0)

            return {
                ...item,
                options,
                total
            }
      })
)

const CartHandler = ({ children }) => {
    const client = useApolloClient()

    let bookingReference
    let localStorageCart = React.useMemo(() => typeof window !== 'undefined' ? window.localStorage.getItem('cart') : null)
    let apiToken = React.useMemo(() => typeof window !== 'undefined' ? window.localStorage.getItem('apiToken') : null)
    if(localStorageCart) {
        localStorageCart = JSON.parse(localStorageCart)
        // If the cart's expired, then we remove the reset it.
        if(moment(localStorageCart.expiresAt).isBefore(moment())) {
            window.localStorage.removeItem('apiToken')
            window.localStorage.removeItem('cart')
            localStorageCart = null
        } else {
            // Get the booking reference from localstorage's cart
            bookingReference = localStorageCart.bookingReference
        }

    }

    let localStorageConfirm = React.useMemo(() => typeof window !== 'undefined' ? window.localStorage.getItem('confirm') : null)
    if(localStorageConfirm) {
        localStorageConfirm = JSON.parse(localStorageConfirm)
    }

    const [cartItems, setCartItems] = React.useState(localStorageCart ? localStorageCart.cartItems : [])
    const [confirmData, setConfirmData] = React.useState(localStorageConfirm ? localStorageConfirm : null)
    const [paymentForm, setPaymentForm] = React.useState(null)
    const [paymentStatus, setPaymentStatus] = React.useState(null)
    const [sessionTokenExpired, setSessionTokenExpired] = React.useState(false)

    let currentCart = typeof window !== 'undefined' ? window.localStorage.getItem('cart') : null
    currentCart = currentCart ? JSON.parse(currentCart) : null
    const cartTotal = currentCart ? currentCart.cost : 0

    const syncWithServerCart = (serverCart) => {
        let currentCart = window.localStorage.getItem('cart')
        currentCart = currentCart ? JSON.parse(currentCart) : null

        // Get the ids of all the items in the server side cart
        const serverIds = map(serverCart.cartItems, 'id')

        // If there are items in the server side cart, then update the local info;
        // else, reset the cart.
        if(serverIds.length) {
            // Remove any item which id is not in the server
            const filteredCartItems = filter(currentCart.cartItems, cartItem => (
                serverIds.includes(cartItem.id)
            ))

            const serverCartItemsNested = nestCartItems(serverCart.cartItems)

            const newCart = {
                ...serverCart,
                expiresAt: moment(serverCart.expiresAt).format(),
                cartItems: map(filteredCartItems, (cartItem) => {
                    const cartServerItem = find(serverCartItemsNested, { id: cartItem.id })
                    return ({
                        ...cartItem,
                        ...cartServerItem,
                        // options: cartServerItem ? cartServerItem.options : []
                    })
                })
            }

            window.localStorage.setItem('cart', JSON.stringify(newCart))

            setCartItems(newCart.cartItems)
        } else {
            resetCart()
        }
    }

    const addToLocalCart = React.useCallback((item) => {
        const newCartItems = [
            ...cartItems,
            item
        ]

        let currentCart = window.localStorage.getItem('cart')
        currentCart = currentCart ? JSON.parse(currentCart) : null

        const newCart = {
            ...currentCart,
            bookingReference: item.bookingReference,
            cartItems: newCartItems
        }

        // Clear "Confirm" data (if there's any)
        window.localStorage.removeItem('confirm')
        setConfirmData(null)

        // debugger

        window.localStorage.setItem('cart', JSON.stringify(newCart))
        setCartItems(newCart.cartItems)
    }, [cartItems])

    const removeFromCart = React.useCallback((cartItemId) => {
        return client.mutate({
            mutation: REMOVE_TOUR,
            variables: {
                bookingReference: bookingReference,
                cartTourID: cartItemId,
            },
        }).then((res) => {
            const {
                data: { removeTour }
            } = res

            syncWithServerCart(removeTour)
        }).catch((err) => console.error(err))
    }, [bookingReference, syncWithServerCart])

    const resetCart = React.useCallback(() => {
        if(typeof window !== 'undefined') {
            window.localStorage.removeItem('cart')
            window.localStorage.removeItem('apiToken')
        }
        setCartItems([])
    }, [setCartItems])

    const bookTour = React.useCallback(async ({
        tourCode,
        departureDate,
        numAdults = 0,
        numChildren = 0,
        numInfants = 0,
        options = [],


        // Frontend specific
        tourName,
        pickup,
        dropoff,
        tourDurationHours,
        tourDurationMinutes,
        cardImage,
        fares
    }) => {
        let initBookingReference = bookingReference

        if(!initBookingReference || !localStorage.getItem('apiToken')) {
            const initCartRes = await client.mutate({
                mutation: INIT_CART
            })

            localStorage.setItem('apiToken', initCartRes.data.initialiseCart.token)
            initBookingReference = initCartRes.data.initialiseCart.cart.bookingReference
        }

        return client.mutate({
            mutation: ADD_TOUR,
            variables: {
                bookingReference: initBookingReference,
                tourCode,
                departureDate,
                numAdults,
                numChildren,
                numInfants,
                options,
                pickupID: pickup ? pickup.id : undefined,
                dropoffID: dropoff ? dropoff.id : undefined
            }
        }).then(async (res) => {
            const { data: { addTour } } = res

            addToLocalCart({
                ...addTour,
                departureDate,
                pickup,
                dropoff,
                fares,
                tourName,
                tourDurationHours,
                tourDurationMinutes,
                cardImage,
                // After fetching the cart we will update this value
                // including in the total the added Options.
                // Add this point the total is only the price of the tour itself.
                total: addTour.cost
            })

            await client.query({
                query: GET_CART,
                fetchPolicy: 'network-only',
                variables: {
                    bookingReference: addTour.bookingReference
                },
            }).then((res) => {
                const { data: { getCart } } = res

                syncWithServerCart(getCart)
            }).catch((err) => {
                // A fatal error happened in the backend
                resetCart()
                throw err
            })

            return res
        }).catch((err) => {
            // A fatal error happened in the backend
            resetCart()
            throw err
        })
    }, [bookingReference, addToLocalCart, syncWithServerCart, resetCart])

    const addPassengerDetails = React.useCallback(async ({
        firstName,
        lastName,
        phonePrefix,
        phoneNumber,
        email
    }) => {
        // Right before paying, we make a last check to make sure the server cart wasn't paid yet.
        const cartServer = await client.query({
            query: GET_CART,
            fetchPolicy: 'network-only',
            variables: {
                bookingReference
            },
        })

        if(cartServer.data.getCart.cartStatus === 'PAYMENT_SUCCESSFUL') {
            throw confirm()
        }

        return client.mutate({
            mutation: ADD_PASSENGER_DETAILS,
            variables: {
                bookingReference,
                passengers: [{
                    index: 0,
                    isLeadTraveller: true,
                    firstName,
                    lastName,
                    phoneNumber: `(+${phonePrefix}) ${phoneNumber}`,
                    email,
                    category: 'ADULT'
                }]
            }
        })
        .then((res) => {
            console.log('added passenger', res)
        }).catch((err) => {
            // A fatal error happened in the backend
            resetCart()
        })

    }, [bookingReference, resetCart])

    const confirm = React.useCallback(() => {
        window.localStorage.setItem('confirm', JSON.stringify(localStorageCart))
        setConfirmData(localStorageCart)
        navigate('/confirm')
        resetCart()
        return new Promise((res) => res())
    }, [setConfirmData, resetCart, localStorageCart])

    const pay = React.useCallback(async (formData) => {

        logtail.info("payment sent", localStorageCart)            

        // Right before paying, we make a last check to make sure the server cart wasn't paid yet.
        const cartServer = await client.query({
            query: GET_CART,
            fetchPolicy: 'network-only',
            variables: {
                bookingReference
            },
        })

        if(cartServer.data.getCart.cartStatus === 'PAYMENT_SUCCESSFUL') {
            throw confirm()
        }

        const urlArr = window.location.href.split('/')
        return client.query({
            query: GET_PAYMENT_SESSION_ID,
            variables: {
                cost: cartTotal,
                bookingReference,
                // [http|https]:://[domain:port]/payment
                returnURL: `${urlArr[0]}//${urlArr[2]}/payment`,
            }
        })
        .then((res) => {
            const { data, errors } = res

            if(errors) {
                throw errors
            }

            const { getPaymentSessionID } = data

            setPaymentStatus('WAITING')

            setPaymentForm({
                SessionId: getPaymentSessionID,
                ...formData
            })

        })
        .catch((err) => {
            // Cart data unsync from server
            
            logtail.error("payment error", err)            

            resetCart()
        }).finally(() => {
            logtail.flush()
        })
    }, [localStorageCart, cartItems, cartTotal, bookingReference, confirm, resetCart, resetCart])


    const checkPaymentResult = React.useCallback(() => {
        logtail.info("check payment")
        return client.query({
            query: GET_PAYMENT_CONFIRMATION,
            fetchPolicy: 'network-only',
            variables: {
                bookingReference
            }
        }).then((res) => {
            const { data, errors } = res

            if(errors) {
                throw errors
            }

            const newPaymentStatus = data.getPaymentConfirmation.status

            setPaymentStatus(newPaymentStatus)

            if(newPaymentStatus === 'APPROVED') {
                confirm()
            } else if(newPaymentStatus !== 'WAITING')  {
                navigate('/checkout')
            }
        }).catch((err) => {
            logtail.error("check payment error", err)
            // A fatal error happened in the backend
            resetCart()
        }).finally(() => {
            logtail.flush()
        })
    }, [bookingReference, setPaymentForm, setPaymentStatus, setConfirmData, confirm, resetCart])

    const childContext = React.useMemo(() => ({
        apiToken,
        bookingReference,
        cartTotal,
        cartItems,
        totalCartItems: cartItems.length,
        removeFromCart,
        bookTour,
        resetCart,
        addPassengerDetails,
        pay,
        paymentForm,
        paymentStatus,
        checkPaymentResult,
        confirmData,
        sessionTokenExpired,
        setSessionTokenExpired
    }), [
        apiToken,
        bookingReference,
        cartTotal,
        cartItems,
        removeFromCart,
        bookTour,
        resetCart,
        addPassengerDetails,
        pay,
        paymentForm,
        paymentStatus,
        checkPaymentResult,
        confirmData,
        sessionTokenExpired,
        setSessionTokenExpired
    ])


    return (
        <CartHandlerContext.Provider value={childContext}>
            {children}
        </CartHandlerContext.Provider>
    )
}

CartHandler.Context = CartHandlerContext

export default CartHandler
