import React                from 'react'
import cx                   from 'utils/classnames'
import PropTypes            from 'utils/prop-types'
import Item, {
  AccordionToggler,
  AccordionTitle,
  AccordionContent,
}                           from './Item'
import styles               from './styles.module.scss'


const Accordion = (props) => {
  const {
    className,
    children
  } = props

  return (
    <div
      className={cx(styles.accordion, className)}
    >
      {children}
  </div>
  );
}

Accordion.propTypes = {
  classes: PropTypes.object
}

Accordion.Item    = Item
Accordion.Toggler = AccordionToggler
Accordion.Title   = AccordionTitle
Accordion.Content = AccordionContent


export default Accordion;
