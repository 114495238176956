import React                from "react"
import cx                   from 'utils/classnames'
import {
  useContext,
  getContextState
}                           from 'entrada-ui/utils'
import Chevron              from "entrada-ui/Accordion/Chevron"
import RawHtml              from 'entrada-ui/RawHtml'
import styles               from './styles.module.scss'

const AccordionItemContext = React.createContext()

const AccordionItem = (props) => {
  const {
    className,
    children,
    keepOpen = false,
    classes = {},
  } = props

  const [isOpen, setIsOpen] = React.useState(false)

  const toggleAccordion = React.useCallback((ev) => {
    setIsOpen(!isOpen)
  }, [isOpen, setIsOpen])

  const childContext = React.useMemo(() => ({
    isOpen,
    keepOpen,
    toggleAccordion
  }), [
    isOpen,
    keepOpen,
    toggleAccordion
  ])

  return (
    <AccordionItemContext.Provider value={childContext}>
      <div
        className={cx(
          styles.accordionItem,
          classes.root,
          className,
          {
            [styles.open]: keepOpen || isOpen,
            [classes.open]: keepOpen || isOpen,
          },
        )}
      >
        {children}
      </div>
    </AccordionItemContext.Provider>
  )
}

const AccordionToggler = (props) => {
  const {
    className,
    children
  } = props

  const context = useContext(AccordionItemContext)
  const { keepOpen } = getContextState({
    props,
    states: ['keepOpen'],
    context
  })

  return (
    <div className={cx(styles.toogler, className)} onClick={!keepOpen ? context.toggleAccordion : undefined}>
        {children}
        <Chevron className={cx(styles.icon)} width={10} fill={"#2699FB"} />
    </div>
  )
}

const AccordionContent = (props) => {
  const {
    className,
    classes = {},
    children
  } = props

  const ref = React.useRef(null)
  const [scrollHeight, setScrollHeight] = React.useState(0)

  const context = useContext(AccordionItemContext)
  const { keepOpen, isOpen } = getContextState({
    props,
    states: ['keepOpen', 'isOpen'],
    context
  })

  React.useEffect(() => {
    if(ref) {
      setScrollHeight(ref.current.scrollHeight)
    }
  }, [ref])

  const height = !isOpen ? 0 : `${scrollHeight}px`

  return (
    <div ref={ref} style={{ maxHeight: !keepOpen ? height : undefined }} className={cx(styles.content, className)}>
      <div className={cx(styles.contentBody, classes.body)}>

        {children}
      </div>
    </div>
  )
}

const AccordionTitle = ({ className, children }) => (
  <h3 className={cx(styles.title, className)}>
    {children}
  </h3>
)

export { AccordionToggler, AccordionTitle, AccordionContent }
export default AccordionItem
