import React                  from 'react'
// import Img                    from "gatsby-image"
import cx                     from 'utils/classnames'
import logo                   from 'images/logo.svg'
import logoDark               from 'images/logo-dark.svg'
import Link                   from 'components/Link'
import { NavBarContext }      from 'entrada-ui/NavBar'
import { useContext }         from 'entrada-ui/utils'
import styles                 from './styles.module.scss'

const Logo = ({
    className,
    dark = false,
}) => {
    const context = useContext(NavBarContext)
    const handleLinkClick = () => context.setOpen(false)

    return (
        <Link 
            className={styles.link} to={'/'}
            onClick={handleLinkClick}
        >
            <img
                alt="GreatSights logo"
                className={cx(
                    styles.root,
                    className
                    )}
                src={dark ? logoDark : logo}
                />
        </Link>
    )
}

export default Logo
