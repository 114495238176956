import React                  from 'react'
import styles                 from './styles.module.scss'

const MenuIcon = () => {
    return (
        <div className={styles.root}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default MenuIcon
