import React                from 'react'
import cx                   from 'utils/classnames'
import styles               from './styles.module.scss'

const NavBar = React.memo(React.forwardRef(function NavBar(props, ref) {
    const {
        className,
        children,
        component: Component = 'nav',
        variant = 'standard',
        ...wrapperProps
    } = props

    const [open, setOpen] = React.useState(false)

    const childContext = {
        variant,
        open,
        setOpen,
    }

    return (
        <NavBarContext.Provider value={childContext}>
            <Component
                ref={ref}
                className={cx(styles.root, className)}
                role="navigation"
                {...wrapperProps}
            >
                <div className={styles.body}>
                    {children}
                </div>
            </Component>
        </NavBarContext.Provider>
    )
}))

export const NavBarContext = React.createContext()
export default NavBar
