import React                  from 'react'
import {
    Link as LinkGatsby,
}                             from 'gatsby'

const Link = ({ to, children, ...props }) => (
    /^\//i.test(to) ?
    <LinkGatsby to={to} {...props}>{children}</LinkGatsby> :
    <a href={to} {...props} rel="noopener noreferrer" target="_blank">{children}</a>
)

Link.etgName = 'Link'

export default Link
