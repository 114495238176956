import React                from 'react'
import PropTypes            from 'utils/prop-types'
import cx                   from 'utils/classnames'
import styles               from './styles.module.scss'

const Spacer = ({ className, classes = {}, ...props}) => (
    <span
        className={cx(
            styles.spacer,
            className,
            {
                [classes.root]: classes
            }
        )}
        {...props}
    />
)

Spacer.propTypes = {
    classes: PropTypes.object
}

export default Spacer
