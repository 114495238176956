import React                from 'react'

const BrowserUpdate = (props) => {
    React.useEffect(() => {
        if(typeof window !== 'undefined') {
            window.$buoop = {required:{e:-1,f:-6,o:-6,s:-1,c:-6},insecure:true,mobile:false,style:"bottom",api:2020.01 };
            window.$buo_f = function(){
                const e = document.createElement("script");
                e.src = "//browser-update.org/update.min.js";
                document.body.appendChild(e);
            };

            setTimeout(() => {
                window.$buo_f();
            }, 2000);
        }
    }, [])

    return null
}

export default BrowserUpdate
