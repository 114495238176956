import React                from 'react'
import {
  isEqual,
  transform,
  isObject
}                           from 'lodash-es'

export function isEtgElement(element, names) {
  // Note that the order of:
  //   element.type.name || element.type
  // is correct, because if the type is an object, then type.name is the name of the component,
  // if type is not an object, then it's a string that is the name of a built in component (div, span, etc)
  return React.isValidElement(element) && (
    names.indexOf(element.type.etgName || element.type.name || element.type) !== -1
  );
}

export function hasValue(value) {
    return value != null && !(Array.isArray(value) && value.length === 0)
}

export function isFilled(obj) {
    return (
        obj &&
        ((hasValue(obj.value) && obj.value !== '') || (hasValue(obj.defaultValue) && obj.defaultValue !== ''))
    )
}

export function deepDiff(object, base) {
	function changes(object, base) {
		return transform(object, function(result, value, key) {
			if (!isEqual(value, base[key])) {
				result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
			}
		});
	}
	return changes(object, base);
}

export function useContext(context) {
    return React.useContext(context)
}

export function getContextState({ props, states, context }) {
    return states.reduce((acc, state) => {
      acc[state] = props[state]

      if (context) {
        if (typeof props[state] === 'undefined') {
          acc[state] = context[state]
        }
      }

      return acc
    }, {})
}

// This is basically an alias but changing states by actions
// export function getContextActions({ props, actions, context }) {
//   return getContextState({ props, states: actions, context })
// }

// From https://usehooks.com/usePrevious/
export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef()

  // Store current value in ref
  React.useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

export function usePortal(id) {
  const rootElemRef = React.useRef(typeof window !== 'undefined' ? document.createElement('div') : null);

  React.useEffect(function setupElement() {
    // We do this check bc of SSR
    if(rootElemRef.current) {
      // Look for existing target dom element to append to
      const parentElem = document.querySelector(`#${id}`)
      // Add the detached element to the parent
      parentElem.appendChild(rootElemRef.current)
      // This function is run on unmount
      return function removeElement() {
        parentElem.removeChild(rootElemRef.current)
      };
    }
  }, []);

  return rootElemRef.current;
}
