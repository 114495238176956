import React                  from 'react'
import cx                     from 'utils/classnames'
import {
    Modal as BsModal
}                             from 'react-bootstrap'
import styles                 from './styles.module.scss'

const Modal = ({ dialogClassName, ...props }) => (
    <BsModal dialogClassName={cx(styles.modalDialog, dialogClassName)} {...props} />
)

Modal.Title = ({ className, ...props }) => (
    <BsModal.Title className={cx(styles.modalTitle, className)} {...props} />
)

Modal.Subtitle = ({ className, ...props }) => (
    <div className={cx(styles.modalSubtitle, className)} {...props} />
)

Modal.Header = BsModal.Header
Modal.Body = BsModal.Body
Modal.Footer = BsModal.Footer

export default Modal
