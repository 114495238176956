import React                  from 'react'
import AppHeader              from 'components/AppHeader'
import AppFooter              from 'components/AppFooter'
import CartHandler            from 'components/CartHandler'
import TokenExpiredModal      from 'components/TokenExpiredModal'
import BrowserUpdate          from 'components/BrowserUpdate'
import { hotjar }             from 'react-hotjar'
import styles                 from './styles.module.scss'
// import 'bootstrap/dist/css/bootstrap.min.css'
import 'css/bootstrap.min.css'
import 'css/fonts.scss'
import 'css/_global.scss'
import AnnouncementsComponent from '../AnnouncementsComponent';

const App = ({ children, props }) => {

    if(typeof window !== 'undefined') {
        if(window.location.hostname != 'localhost') {
            // hotjar.initialize('HOTJAR-ID', 'HOTJAR-SCRIPT-VERSION-NUMBER')
            hotjar.initialize(806135, 6)
        }
    }
    
    return (
        <CartHandler>
            <div className={styles.root}>
                <AppHeader />
                <main>
                    {children}
                </main>
                <AppFooter />
                <AnnouncementsComponent />
                <BrowserUpdate />
                <TokenExpiredModal />
            </div>
        </CartHandler>
    )
}

export default App
